import {SerializedError} from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import cn from 'classnames';
import {Field, FieldProps, Form, Formik, FormikProps} from 'formik';
import {TFunction} from 'i18next';
import {FC, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useMutation} from 'react-query';
import {toast} from 'react-toastify';

import ImportApi from 'api/import';
import ProjectsApi from 'api/projects';
import {GanttColumnConfig} from 'modules/Tasks/components/Gantt/hooks/useGanttColumns';
import {GANTT_COLUMNS_SETTINGS} from 'modules/Tasks/components/Gantt/utils/constants';
import Button from 'shared/components/Button';
import ConfirmationPopup from 'shared/components/Confirmation/ConfirmationPopup';
import FormControl from 'shared/components/CoreForm/FormControl';
import CoreSelect from 'shared/components/CoreForm/Select/Select';
import {CoreOptionType} from 'shared/components/CoreForm/Select/types';
import Switcher from 'shared/components/CoreForm/Switcher';
import FormikChangeWatcher from 'shared/components/FormikChangeWatcher';
import Icon from 'shared/components/Icon';
import Popup from 'shared/components/Popup/Popup';
import TaskCard from 'shared/components/TaskCard';
import {
  DeleteCustomColumnProps,
  TaskImportClientMapping,
  TaskImportField,
  TaskImportPrepResponse,
  TaskImportProjectCustomFieldDef,
  TasksImportConfig,
} from 'shared/components/TasksImport/utils/types';
import {GANTT_PREFERENCES_KEY} from 'shared/constants/common';
import env from 'shared/constants/env';
import {GanttNames} from 'shared/constants/gantt';
import {IconsMap} from 'shared/constants/icons';
import {MixpanelEventTypes} from 'shared/constants/mixpanelEvents';
import {isAxiosError} from 'shared/helpers/axios';
import {toTitleCase} from 'shared/helpers/common';
import {assumeTimezoneDateAsLocal, toIsoString} from 'shared/helpers/dates';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';
import {useCompany} from 'shared/hooks/useCompany';
import {useLocalStorage} from 'shared/hooks/useLocalStorage';
import {useProfile} from 'shared/hooks/useProfile';
import {useProjectSelector} from 'shared/hooks/useProjectSelector';
import {useQueryCache} from 'shared/hooks/useQueryCache/useQueryCache';
import {ProjectCustomFieldType} from 'shared/models/project';
import {TaskDetailsModelDTO} from 'shared/models/task/task';
import {TaskStatusType} from 'shared/models/task/taskStatus';
import {useRootDispatch, useRootSelector} from 'store';
import {selectAllProjects, projectActions} from 'store/projects';
import {updateProject} from 'store/projects/actions';

import {useTasksImportContext} from '../../TasksImportContext/TasksImportContext';
import {TaskImportStep, TasksImportSourceType} from '../../utils/constants';
import {isCSVFile, isMppFile} from '../../utils/functions';
import s from '../styles.module.scss';

import CustomColumnMapping from './CustomColumnMapping';
import {processCustomFieldsApi, updateImportSettings} from './customFieldImportHelpers';
import {getDefaultValues, getMapping, schema} from './schema';

import './style.css';

export type ImportSubmitValues = {
  asyncUploadId: string;
  importedCustomFields: TaskImportProjectCustomFieldDef[];
  values: TasksImportConfig;
};

type TasksImportMappingProps = {
  customColumns: TaskImportProjectCustomFieldDef[];
  defaultProjectId?: string;
  fields: TaskImportField[];
  fileHeaders: {label: string; value: string}[];
  importPercentage: number;
  mixpanelEvents: MixpanelEventTypes['tasks']['import'];
  onProjectChange: (projId: string) => void;
  onSubmit: (values: ImportSubmitValues) => Promise<void>;
};

const getDateFormatOptions = (t: TFunction): CoreOptionType[] => [
  {label: t('import:mapping.date_options.month_first', 'MM/DD/YYYY'), value: 'MM/DD/YYYY'},
  {label: t('import:mapping.date_options.day_first', 'DD/MM/YYYY'), value: 'DD/MM/YYYY'},
  {label: t('import:mapping.date_options.year_first', 'YYYY-MM-DD'), value: 'YYYY-MM-DD'},
];

const TasksImportMapping: FC<TasksImportMappingProps> = ({
  customColumns,
  defaultProjectId,
  fields,
  fileHeaders,
  mixpanelEvents,
  onProjectChange,
  onSubmit,
}) => {
  const dispatch = useRootDispatch();
  const {companyName} = useCompany();
  const worker = useProfile();
  const projects = useRootSelector(selectAllProjects);
  const [isParsing, setParsing] = useState(false);
  const [tasksIsLoading, setTasksIsLoading] = useState(false);
  const [taskPreviewModel, setTaskPreviewModel] = useState<Partial<TaskDetailsModelDTO>>();
  const [customColToDelete, setCustomColToDelete] = useState<DeleteCustomColumnProps>(null);
  const [mappingValues, setMappingValues] = useState<TaskImportClientMapping | Record<string, unknown>>({});
  const {t} = useTranslation(['import', 'project']);

  const [{sourceType, procoreProjects, file, asyncUploadId, parseResult}, actions] = useTasksImportContext();

  const [selectedProject, setSelectedProject] = useState<string>(defaultProjectId);
  const [selectedProcoreProject, setSelectedProcoreProject] = useState<string>(procoreProjects?.[0]?.value);
  const formik = useRef<FormikProps<TasksImportConfig>>();

  const {cacheHelper} = useQueryCache();
  const {mixpanel} = useAnalyticsService();
  const project = useProjectSelector(selectedProject);
  const mixpanelMeta = useMemo(() => Object.assign({}, {'Project Name': project?.name}), [project]);
  const dateFormatOptions = useMemo(() => getDateFormatOptions(t), [t]);

  const isLoading = useMemo(() => {
    const loading =
      ['running', 'queued'].includes(parseResult?.status) ||
      isParsing ||
      (sourceType === TasksImportSourceType.proCore && !procoreProjects);

    return loading;
  }, [parseResult, isParsing, sourceType, procoreProjects]);

  const [getActiveTab] = useLocalStorage<GanttNames>({
    key: GANTT_PREFERENCES_KEY,
    path: `byProject.${selectedProject}.activeTab`,
    defaultValue: null,
  });

  const activeTab = [GanttNames.gantt, GanttNames.ganttVisual, GanttNames.lookahead].some(
    (allowedTab) => getActiveTab() === allowedTab,
  )
    ? getActiveTab()
    : GanttNames.gantt;

  const [getStoredSettings, storeColumnsConfig] = useLocalStorage<GanttColumnConfig[]>({
    key: GANTT_COLUMNS_SETTINGS,
    path: `${worker.id}.${selectedProject}.${activeTab}`,
    defaultValue: null,
    enabled: !!selectedProject && !!worker.id,
  });

  const mailto = `${t(
    'import:mapping.mailto.start',
    'mailto:c4@bycore.com?subject=Help with Import Activities File&body=',
  )}${companyName}${t(
    'import:mapping.mailto.end',
    ' would like help with Importing Activities File. Attach the file you are trying to import',
  )}`;

  const fieldsToSelect = useMemo(() => {
    // required if targetedAmount is selected
    const completionUnit = fields.find((field) => field.key === 'completionUnit');
    if (completionUnit) {
      completionUnit.required = !!mappingValues.completionTarget;
    }
    // Only show predecessors if uniqueId is not 'auto'
    if (file && isCSVFile(file))
      return fields.filter(({key}) => {
        if (key === 'predecessors') {
          return mappingValues.uniqueId !== 'auto';
        }
        return true;
      });
    if (file && !isMppFile(file.name)) return fields.filter(({key}) => key !== 'predecessors');
    // we are automatically mapping for the outlineCode field
    // if file is mpp and hasHierarchy = true
    return fields.filter(({key}) => key !== 'outlineCode' && key !== 'predecessors');
  }, [fields, file, mappingValues.completionTarget, mappingValues.uniqueId]);

  const onFormikValueChanged = useCallback(
    (values: TasksImportConfig) => {
      if (parseResult?.result?.samples?.length) {
        const taskSamples = parseResult.result.samples;
        const relevantTaskSample =
          taskSamples.find((sample) => Object.values(sample).every((value) => value !== null)) || taskSamples[0];
        setTaskPreviewModel({
          uniqueId: relevantTaskSample[values.mapping.uniqueId],
          status: TaskStatusType.tba,
          name: relevantTaskSample[values.mapping.name],
          schedStartDate: toIsoString(
            assumeTimezoneDateAsLocal(relevantTaskSample[values.mapping.schedStartDate], project?.timezone),
          ),
          schedEndDate: toIsoString(
            assumeTimezoneDateAsLocal(relevantTaskSample[values.mapping.schedEndDate], project?.timezone),
          ),
          location: relevantTaskSample[values.mapping.location],
          responsibleParty: relevantTaskSample[values.mapping.subcontractor],
          outlineCode: relevantTaskSample[values.mapping.outlineCode],
        });
      }
      setMappingValues(values.mapping);
    },
    [parseResult?.result.samples, project?.timezone],
  );

  const taskIdOptions = useMemo(
    () =>
      fileHeaders
        .concat({label: t('import:fields.auto_assign.label', 'Auto-Assign Task ID'), value: 'auto'})
        .sort((a, b) => a.label.localeCompare(b.label, 'en', {sensitivity: 'base'})),
    [fileHeaders, t],
  );

  const projectsOptions = useMemo(
    () => projects.map((project) => ({label: project.name, value: project.id})),
    [projects],
  );

  const startFileImport = async (defaultProject: string, selectedFile: File) => {
    setParsing(true);
    try {
      const {
        data: {id, putUrl},
      } = await ImportApi.startImport(defaultProject, selectedFile.name);
      actions.asyncUploadId(id);

      const uploadUrl =
        process.env.NODE_ENV === 'development'
          ? putUrl.replace('https://journey-builders-staging.s3.amazonaws.com/', '/s3/')
          : putUrl;

      await ImportApi.uploadFileToS3(selectedFile, uploadUrl);
      await startPrep(defaultProject, id);
    } catch (error) {
      Sentry.captureException(error, {
        tags: {context: 'S3 upload failure', name: selectedFile.name},
      });
      actions.setErrorCode('s3_upload_error');
    } finally {
      setParsing(false);
    }
  };

  const startProcoreImport = async (projectId: string, procoreProjectId: string) => {
    setParsing(true);
    const res = await ImportApi.startImport(projectId);
    actions.asyncUploadId(res.data.id);
    await ImportApi.extract(selectedProject, res.data.id, procoreProjectId);
    await ImportApi.pollExtractResult(selectedProject, res.data.id);
    await startPrep(projectId, res.data.id);
    setParsing(false);
  };

  const startPrep = async (projectId: string, importId: string) => {
    await ImportApi.prepRun(projectId, importId);
    const prepData = await ImportApi.pollPrepResults(projectId, importId, 100);
    handleStatusResult(prepData);
  };

  const handleStatusResult = (rs: TaskImportPrepResponse) => {
    if (rs.status === 'finished') {
      if (rs.result.errors) {
        actions.setErrorCode(rs.result.errors[0].code);
      } else if (rs.result.error) {
        actions.setErrorCode('500');
      } else {
        const tmp = Object.assign(rs, {
          result: {
            ...rs.result,
            headers: rs.result.headers.filter(Boolean).sort(new Intl.Collator('en', {sensitivity: 'base'}).compare),
          },
        });
        actions.setParseResult(tmp);
      }
    }
  };

  useEffect(() => {
    if (projects.length && formik.current) {
      formik.current.setFieldValue('defaultProject', selectedProject ? selectedProject : projects[0].id);
    }
  }, [projects, selectedProject]);

  useEffect(() => {
    if (formik.current) {
      formik.current.setSubmitting(isLoading);
    }
  }, [isLoading]);

  const saveMapping = async (values: TasksImportConfig) => {
    const updatingProject = projects.find((project) => project.id === values.defaultProject);
    const {mapping} = values;

    if (file && isMppFile(file.name)) {
      if (values.importWithHierarchy) {
        mapping.outlineCode = 'outline_code';
      } else {
        delete mapping.outlineCode;
      }
    }

    const preparedMapping = JSON.stringify({mapping: mapping, dateFormat: values.dateFormat});
    if (updatingProject.importSettings === preparedMapping) {
      return;
    }
    const res = await dispatch(
      updateProject({
        ...updatingProject,
        importSettings: preparedMapping,
      }),
    );
    if (updateProject.rejected.match(res)) {
      throw new Error(res.error.message);
    } else {
      return res.payload;
    }
  };

  const {mutateAsync: handleSaveMapping} = useMutation(saveMapping, {
    onError: (err: SerializedError) => {
      toast.error(err.message);
      Sentry.captureException(err, {
        tags: {context: 'Import saveMapping failure', name: file?.name || 'Unknown file'},
      });
    },
    onSuccess: (res, values) => {
      actions.setConfig(values);
      toast.success(t('import:toast.success.save', 'Project mapping saved!'));
      cacheHelper.findRecentPagedQuery('project')?.setData((data) => cacheHelper.updatePagedData(data, res));
    },
  });

  const saveCustomFields = async (values: TasksImportConfig) => {
    try {
      if (!values.customFields.length) return;
      const {customFields} = values;
      const updatingProject = projects.find((project) => project.id === values.defaultProject);

      if (!updatingProject) {
        throw new Error('Project not found');
      }

      const {updated, created, failed} = await processCustomFieldsApi(
        customFields,
        values.defaultProject,
        updatingProject.customFieldDef,
      );

      const preparedMapping = updateImportSettings(updatingProject.importSettings, customFields, updated, created);

      if (created.length > 0) {
        const existingConfig = getStoredSettings() || [];
        const filteredConfig = existingConfig.filter(
          (existing) => !created.some((c) => c.internalFieldName === existing.name),
        );
        const newColumnSettings: GanttColumnConfig[] = [
          ...filteredConfig,
          ...created.map(({internalFieldName}) => ({
            name: internalFieldName,
            hideByUser: false,
            defaultHide: false,
            touched: false,
            isCustom: true,
          })),
        ];
        storeColumnsConfig(newColumnSettings);
      }

      await dispatch(
        updateProject({
          ...updatingProject,
          importSettings: preparedMapping,
        }),
      );

      dispatch(
        projectActions.updateProject({
          id: values.defaultProject,
          changes: {
            customFieldDef: updatingProject?.customFieldDef.concat(created),
          },
        }),
      );

      const updatedCustomFieldDef = updatingProject.customFieldDef.map((field) => {
        const updatedField = updated.find((u) => u.internalFieldName === field.internalFieldName);
        return updatedField || field;
      });

      if (updatedCustomFieldDef.length > 0) {
        updatedCustomFieldDef.forEach((field) => {
          const model = {
            fieldData: field.fieldData,
            fieldName: field.fieldName,
          };
          dispatch(
            projectActions.updateCustomColumn({
              projectId: values.defaultProject,
              internalFieldName: field.internalFieldName,
              ...model,
            }),
          );
        });
      }

      const fieldsToImport = customFields.map((field) => {
        const normalizedFieldName = toTitleCase(field.fieldName.trim().replace(/\s+/g, ' '));
        const internalFieldName = [...updated, ...created].find(
          (cfRes) => cfRes.fieldName === normalizedFieldName,
        ).internalFieldName;

        return {...field, fieldName: normalizedFieldName, internalFieldName};
      });

      if (failed.length > 0) {
        toast.error(
          t('import:errors.messages.failed_to_process', {
            count: failed.length,
            name: failed.map((col) => col.data.fieldName || 'Unknown Field').join(', '),
          }),
        );
      }

      return fieldsToImport || [];
    } catch (error) {
      throw error;
    }
  };

  const {mutateAsync: handleSaveCustomFields} = useMutation(saveCustomFields, {
    onError: (err: Error) => {
      toast.error(t('import:errors.messages.custom_column'));
      Sentry.captureException(err, {
        tags: {context: 'Import saveCustomFields failure', name: file?.name || 'Unknown file'},
      });
    },
    onSuccess: (fieldsToImport) => {
      if (!fieldsToImport) {
        return;
      }
      const values = formik.current.values;
      formik.current.setValues({...values, customFields: fieldsToImport});
      toast.success(t('import:toast.success.custom_column'));
    },
  });

  const deleteCustomColumn = async ({customFields, idx}: DeleteCustomColumnProps) => {
    const {internalFieldName, fieldName} = customFields[idx];
    try {
      await ProjectsApi.deleteCustomFieldDef(selectedProject, internalFieldName);
      dispatch(
        projectActions.updateProject({
          id: selectedProject,
          changes: {
            customFieldDef: customColumns.filter((col) => col.internalFieldName !== internalFieldName),
          },
        }),
      );
      const existingConfig = getStoredSettings() || [];
      const filteredConfig = existingConfig.filter((existing) => internalFieldName !== existing.name);
      storeColumnsConfig(filteredConfig);
      return fieldName;
    } catch (error) {
      if (isAxiosError(error)) {
        throw error;
      }
    }
  };

  const {mutateAsync: handleDeleteCustomColumn} = useMutation(deleteCustomColumn, {
    onSuccess: (fieldName, {helpers, customFields, idx}) => {
      helpers.remove(idx);
      toast.success(
        t('project:confirm.delete_custom_column.toast.success', {
          columnName: fieldName,
        }),
      );
      const updatedFields = customFields.filter((field, i) => i !== idx);
      if (formik.current) {
        const {setValues} = formik.current;
        setValues((prev) => ({...prev, customFields: updatedFields}));
      }
    },
    onError: (e) => {
      Sentry.captureException(e, {tags: {context: 'Delete Custom Column from Import'}});
      toast.error(t('project:confirm.delete_custom_column.toast.error'));
    },
  });

  const handleCloseDeleteConfirmation = () => setCustomColToDelete(null);

  const handleSubmit = async (values: TasksImportConfig) => {
    await handleSaveMapping(values);
    const importedCustomFields = await handleSaveCustomFields(values);
    setTasksIsLoading(true);
    await onSubmit({asyncUploadId, importedCustomFields: importedCustomFields || [], values});
    setTasksIsLoading(false);
  };

  useEffect(() => {
    if (!parseResult) return;
  }, [parseResult]);

  useEffect(() => {
    if (sourceType === TasksImportSourceType.file) {
      startFileImport(selectedProject, file);
    } else if (selectedProcoreProject) {
      startProcoreImport(selectedProject, selectedProcoreProject);
    }
  }, [sourceType, selectedProcoreProject]);

  useEffect(() => {
    if (!selectedProcoreProject && procoreProjects) {
      setSelectedProcoreProject(procoreProjects[0]?.value);
    }
  }, [procoreProjects, selectedProcoreProject]);

  useEffect(() => {
    if (parseResult?.result?.hasHierarchy) {
      const values = formik.current.values;
      formik.current.setValues({
        ...values,
        importWithHierarchy: true,
        mapping: {...values.mapping, outlineCode: 'outline_code'},
      });
    }
  }, [parseResult?.result?.hasHierarchy]);

  // Add effect to clear predecessors when uniqueId is set to 'auto'
  useEffect(() => {
    if (mappingValues.uniqueId === 'auto') {
      formik.current?.setFieldValue('mapping.predecessors', null);
    }
  }, [mappingValues.uniqueId]);

  const disabledOptions = (option, values: TasksImportConfig) =>
    [...Object.values(values.mapping), ...values.customFields.map((cf) => cf.mappedTo).filter(Boolean)].includes(
      option.value,
    );

  const initialValues: TasksImportConfig = useMemo(() => {
    let mapping = getDefaultValues(dateFormatOptions[0].value).mapping;

    const defaultProject = selectedProject || (projects.length ? projects[0].id : '');

    try {
      const project = projects.find((project) => project.id === selectedProject);
      if (project && project.importSettings && fileHeaders.length) {
        const importSettings = JSON.parse(project.importSettings);
        mapping = getMapping(mapping, importSettings.mapping, fileHeaders);
        return {
          ...getDefaultValues(dateFormatOptions[0].value),
          defaultProject: selectedProject,
          customFields: customColumns,
          mapping,
        };
      }
    } catch (error) {
      if (env.NODE_ENV === 'development') {
        console.warn(error);
      }
    }

    return {
      ...getDefaultValues(dateFormatOptions[0].value),
      defaultProject,
      customFields: customColumns,
      mapping,
    };
  }, [dateFormatOptions, selectedProject, projects, customColumns, fileHeaders]);

  return (
    <>
      <Formik<TasksImportConfig>
        enableReinitialize
        initialValues={initialValues}
        innerRef={formik}
        onSubmit={handleSubmit}
        validationSchema={schema(t)}
        validateOnBlur
        validateOnMount={false}
      >
        {({setFieldValue, submitForm, values, isSubmitting}) => (
          <>
            <FormikChangeWatcher onChange={onFormikValueChanged} />
            <Popup.Body>
              <div className="compare-grid">
                <div className="compare-grid__content">
                  <Form className="form-compare">
                    {parseResult?.result?.hasHierarchy && (
                      <div className="form-default__item form-default__item--full">
                        <Field name="importWithHierarchy">
                          {({field}: FieldProps) => (
                            <Switcher
                              {...field}
                              onChange={(value) => {
                                const event = value ? mixpanelEvents.toggleWBSOn : mixpanelEvents.toggleWBSOff;
                                mixpanel.trackWithAction(() => setFieldValue(field.name, value), event);
                              }}
                              disabled={isLoading}
                              label={t('import:mapping.form.hierarchy.label', 'Import With Hierarchy (WBS)')}
                            />
                          )}
                        </Field>
                      </div>
                    )}
                    <div className="form-compare__default">
                      <div className="form-compare__item form-compare__item--default">
                        <FormControl
                          name="dateFormat"
                          label={t('import:mapping.form.date_format.label', 'Select Date Format You Use')}
                        >
                          <Field>
                            {({field}: FieldProps) => (
                              <CoreSelect
                                options={dateFormatOptions}
                                value={field.value}
                                onChange={(value) =>
                                  mixpanel.trackWithAction(
                                    () => setFieldValue(field.name, value),
                                    mixpanelEvents.selectDateFormat,
                                    mixpanelMeta,
                                  )
                                }
                              />
                            )}
                          </Field>
                        </FormControl>
                      </div>
                      <div className="form-compare__item form-compare__item--default">
                        <FormControl
                          name="defaultProject"
                          label={t('import:mapping.form.default_project.label', 'Default Project')}
                        >
                          <Field>
                            {({field}: FieldProps) => (
                              <CoreSelect
                                options={projectsOptions}
                                value={field.value}
                                onChange={(value) =>
                                  mixpanel.trackWithAction(
                                    () => {
                                      setFieldValue(field.name, value);
                                      setSelectedProject(value);
                                      onProjectChange(value);
                                    },
                                    mixpanelEvents.selectProject,
                                    {'Project Name': value},
                                  )
                                }
                              />
                            )}
                          </Field>
                        </FormControl>
                      </div>
                      {sourceType === TasksImportSourceType.proCore && (
                        <div className="form-compare__item form-compare__item--default">
                          <FormControl
                            name="procoreProject"
                            label={t('import:mapping.form.procore_project.label', 'Procore Project')}
                          >
                            <CoreSelect
                              options={procoreProjects}
                              isLoading={!procoreProjects}
                              loadingMessage={() => t('import:mapping.form.procore_project.loading', 'Loading...')}
                              value={selectedProcoreProject}
                              onChange={(value) =>
                                mixpanel.trackWithAction(
                                  () => {
                                    setSelectedProcoreProject(value);
                                  },
                                  mixpanelEvents.selectProject,
                                  {'Project Name': value},
                                )
                              }
                            />
                          </FormControl>
                        </div>
                      )}
                    </div>
                    <div className="form-compare__body">
                      {fieldsToSelect.map((importField) => (
                        <div key={importField.key} className="form-compare__control">
                          <span className="form-compare__name">
                            {importField.label}{' '}
                            {!!importField.required && <span>*{t('import:mapping.form.symbol.required', '*')}</span>}
                          </span>
                          <Icon
                            colorFill
                            className="form-compare__icon-compare"
                            size={24}
                            name={IconsMap.arrow_forward}
                          />
                          <div className="form-compare__item form-compare__item--compare">
                            <FormControl name={`mapping.${importField.key}`} labelHidden label={importField.label}>
                              <Field>
                                {({field}: FieldProps) => (
                                  <CoreSelect
                                    placeholder={
                                      isLoading
                                        ? t('import:mapping.form.select.placeholder.loading', 'Preparing data...')
                                        : t('import:mapping.form.select.placeholder.default', 'Select...')
                                    }
                                    isSearchable
                                    isDisabled={!parseResult}
                                    isLoading={isLoading}
                                    options={importField.key === 'uniqueId' ? taskIdOptions : fileHeaders}
                                    isOptionDisabled={(option) => disabledOptions(option, values)}
                                    value={field.value}
                                    onChange={(value) => {
                                      const setValue = () => setFieldValue(field.name, value);
                                      if (importField.key === 'shouldImport') {
                                        mixpanel.trackWithAction(setValue, mixpanelEvents.shouldImportSelect);
                                      } else {
                                        setValue();
                                      }
                                    }}
                                    menuPlacement="auto"
                                    isClearable
                                  />
                                )}
                              </Field>
                            </FormControl>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="form-compare__body">
                      <CustomColumnMapping
                        fields={fields}
                        fieldsToSelect={fieldsToSelect}
                        fileHeaders={fileHeaders}
                        handleDeleteCustomColumn={setCustomColToDelete}
                        taskIdOptions={taskIdOptions}
                        tasksIsLoading={tasksIsLoading}
                      />
                    </div>
                  </Form>
                </div>
                <div className="compare-grid__aside">
                  <h2 className="compare-preview__title">{t('import:mapping.preview.title', 'Preview')}</h2>
                  <div className="compare-preview__description">
                    {t('import:mapping.preview.description', 'This is how your single activity will look like')}
                  </div>
                  <TaskCard
                    task={taskPreviewModel}
                    initialDateFormat={formik?.current?.values.dateFormat}
                    project={projects.find((proj) => proj.id === formik.current?.values?.defaultProject)}
                    className="compare-preview__card"
                  />
                  <a
                    className="ctrl-btn ctrl-btn--view-border compare-grid__button-help"
                    href={mailto}
                    onClick={() => mixpanel.track(mixpanelEvents.needHelpBtn, mixpanelMeta)}
                  >
                    <Icon colorFill className="ctrl-btn__icon" name="help" />
                    <span className="ctrl-btn__text">
                      {t('import:mapping.preview.help', 'Need help importing activities?')}
                    </span>
                  </a>
                </div>
              </div>
            </Popup.Body>
            <Popup.Footer>
              <div className={s.footer}>
                <Button
                  type="button"
                  disabled={isLoading || tasksIsLoading}
                  icon={
                    <Icon
                      className={cn(s['custom-column-heading--cta-icon'], {
                        [s['custom-column-heading--cta-icon--disabled']]: isSubmitting || tasksIsLoading,
                      })}
                      name={IconsMap.plus}
                    />
                  }
                  onClick={() => {
                    mixpanel.track(mixpanelEvents.addCustomColumn);
                    formik.current?.setFieldValue('customFields', [
                      ...(formik.current.values.customFields || []),
                      {
                        fieldData: '',
                        fieldName: '',
                        fieldType: ProjectCustomFieldType.string,
                        internalFieldName: '',
                        mappedTo: null,
                      },
                    ]);
                  }}
                >
                  {t('import:mapping.form.custom_column.add_custom_column')}
                </Button>
                <div>
                  <Button
                    disabled={isSubmitting || tasksIsLoading}
                    className={`popup__button ${tasksIsLoading ? 'is-processing' : ''}`}
                    type="button"
                    onClick={() =>
                      mixpanel.trackWithAction(
                        () => actions.setCurrentStep(TaskImportStep.SelectSource),
                        mixpanelEvents.nextBtn,
                        mixpanelMeta,
                        !tasksIsLoading,
                      )
                    }
                  >
                    {t('import:mapping.buttons.prev', 'Prev')}
                  </Button>
                  <Button
                    data-cy="btnImportNext"
                    disabled={isLoading || tasksIsLoading}
                    icon={tasksIsLoading ? <Icon colorFill className="ctrl-btn__icon" name="autorenew" /> : null}
                    className={`popup__button ${tasksIsLoading ? 'is-processing' : ''}`}
                    type="button"
                    onClick={() =>
                      mixpanel.trackWithAction(submitForm, mixpanelEvents.nextBtn, mixpanelMeta, !tasksIsLoading)
                    }
                  >
                    {tasksIsLoading
                      ? t('import:mapping.buttons.loading', 'Preparing...')
                      : t('import:mapping.buttons.next', 'Next')}
                  </Button>
                </div>
              </div>
            </Popup.Footer>
          </>
        )}
      </Formik>
      <ConfirmationPopup
        acceptButton={{title: t('project:confirm.delete_custom_column.acceptButton'), type: 'danger'}}
        description={t('project:confirm.delete_custom_column.description', {
          columnName: customColToDelete?.customFields?.[customColToDelete?.idx]?.fieldName ?? '',
        })}
        onAccept={async () => {
          await handleDeleteCustomColumn(customColToDelete);
          handleCloseDeleteConfirmation();
        }}
        onClose={handleCloseDeleteConfirmation}
        onReject={handleCloseDeleteConfirmation}
        title={t('project:confirm.delete_custom_column.title')}
        useNewLayout
        visible={Boolean(customColToDelete)}
      />
    </>
  );
};
export default TasksImportMapping;
