import * as Sentry from '@sentry/browser';
import cn from 'classnames';
import dayjs from 'dayjs';
import {GanttStatic} from 'dhtmlx-gantt';
import {useFormik} from 'formik';
import {Ref, forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import {useQueryClient} from 'react-query';
import {useParams} from 'react-router';
import {toast} from 'react-toastify';
import {ValidationError} from 'yup';

import {useFilterContext} from 'modules/Tasks/components/Filters/FilterProvider';
import {CompletionUnits, completionUnitLabels, taskCompletionUnitOptions} from 'shared/constants/completionUnits';
import {IconsMap} from 'shared/constants/icons';
import {QUERY_CACHE_KEYS} from 'shared/constants/queryCache';
import {formatDate} from 'shared/helpers/dates';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';
import {useCompanyWorkerRoles} from 'shared/hooks/useCompanyWorkerRoles';
import {MessageTypeMap} from 'shared/models/feedback';
import {IOC_TYPES} from 'shared/models/ioc';
import {TaskDetailsModelDTO, TaskModelRawDTO, TaskCompletionUnits} from 'shared/models/task/task';
import {useInjectStore} from 'shared/providers/injection';
import {TasksStoreType} from 'shared/stores/TasksStore';
import {normalizeI18Key} from 'shared/utils/normalizeI18Key';
import {useRootDispatch} from 'store';
import {taskActions} from 'store/tasks';

import Button from '../Button';
import ConfirmationPopup from '../Confirmation/ConfirmationPopup';
import Icon from '../Icon';
import SkeletonPreloader from '../SkeletonPreloader';
import {HSpacer} from '../Spacer/HSpacer';
import {VSpacer} from '../Spacer/VSpacer';

import DailyCard, {DailyCardUpdates} from './components/DailyCard/DailyCard';
import {InlineInput, InlineSelect} from './components/InlineFormElements';
import {
  ProgressReportUpdates,
  FeedbackMessage,
  FeedbackImage,
  useHandleSubmitProgressReport,
} from './hooks/useHandleSubmitProgressReport';
import {useProgressReport} from './hooks/useProgressReport';
import {useScrollToCurrentDayAfterLoad} from './hooks/useScrollAfterLoading';
import styles from './progressReport.module.scss';
import {
  CommentValues,
  ImageError,
  ProgressReportCommentForm,
} from './ProgressReportCommentForm/ProgressReportCommentForm';
import {parseAndValidateNumber} from './utils/helpers';
import {ONE_HUNDRED_PERCENT, validationSchema} from './utils/validationSchema';

interface ProgressReportProps {
  gantt?: GanttStatic;
  onClose?: () => void;
  taskId?: string;
}

interface Errors {
  completionAmount?: string;
  projectedLabor?: string;
  image?: string;
}

export type ProgressReportRef = {
  hasChanges: boolean;
};

type FormValues = Omit<ProgressReportUpdates, 'initialFeedBack'>;

/**
 * ! Do not remove!
 * t("dailies:progress_report.header.error.completion_amount_error")
 * t("dailies:progress_report.header.error.completion_amount_error_zero")
 * t("dailies:progress_report.header.error.daily_completion_amount_error")
 */

const initialComment: FeedbackMessage = {
  comment: '',
  dateTag: dayjs().toDate(),
  feedbackType: MessageTypeMap.message,
  flagAsPotentialIssue: false,
};

const initialImage: FeedbackImage = {
  dateTag: dayjs().toDate(),
  feedbackType: MessageTypeMap.image,
  images: [],
};

function ProgressReport({gantt, onClose, taskId}: ProgressReportProps, ref: Ref<ProgressReportRef>) {
  const {t} = useTranslation(['dailies', 'common']);
  const {queryParams} = useFilterContext();
  const {taskId: idParam, projectId} = useParams<{taskId: string; projectId: string}>();
  const id = idParam ?? taskId;
  const tasksStore = useInjectStore<TasksStoreType>(IOC_TYPES.TasksStore);
  const dispatch = useRootDispatch();
  const queryClient = useQueryClient();
  const {
    mixpanel: {events, track, trackWithAction},
  } = useAnalyticsService();

  const feedbackRef = useRef<DailyCardUpdates[]>(undefined);

  const taskRef = useRef<TaskDetailsModelDTO>(null);
  const [lockCompletionUnits, setLockCompletionUnits] = useState(false);
  const [showUnitsUnlockPopup, setShowUnitsUnlockPopup] = useState(false);
  const [errors, setErrors] = useState<Errors>({});
  const [childImageErrors, setChildImageErrors] = useState<ImageError>(null);

  const progressReportRef = useRef<HTMLFormElement>(null);

  const {isLoading, data, error, status} = useProgressReport(id);

  useScrollToCurrentDayAfterLoad({progressReportRef, isLoading, status});

  useEffect(() => {
    if (data) {
      const {initialFeedback, initialTask, lockedCompletionUnits} = data;
      feedbackRef.current = initialFeedback;
      taskRef.current = initialTask;
      setLockCompletionUnits(lockedCompletionUnits);
    }
    if (error) {
      toast.error(t('dailies:progress_report.toast.error_loading'));
    }
  }, [data, error, t]);

  const onSubmit = useHandleSubmitProgressReport({
    onSuccess: async (data) => {
      const currentTask = tasksStore.getTaskById(id);
      const currentCommentCount = Number(currentTask?.comment_count) || 0;
      const newCommentCount = currentCommentCount + data.comment_count;
      const taskUpdates: Partial<TaskModelRawDTO> = Object.assign(
        {} as Partial<TaskModelRawDTO>,
        data?.taskUpdateResponse?.task,
        {
          comment_count: newCommentCount,
        },
      );
      tasksStore.updateTasks([taskUpdates]);
      const updatedTask = {
        ...formik.values.task,
        completionTarget: data?.taskUpdateResponse?.task?.completion_target,
        completionUnit: data?.taskUpdateResponse?.task?.completion_unit,
        projectedLabor: data?.taskUpdateResponse?.task?.projected_labor,
        completionAmount: data?.taskUpdateResponse?.task?.completion_amount,
      };
      dispatch(taskActions.updateTask(updatedTask));
      toast.success(t('dailies:progress_report.toast.success'));
      const invalidators = [
        QUERY_CACHE_KEYS.task(id),
        QUERY_CACHE_KEYS.dailiesTaskFeedback(id),
        QUERY_CACHE_KEYS.dailiesReport(projectId, queryParams),
        QUERY_CACHE_KEYS.feedback(taskId),
        // will need to change queryKey when AJ is done with his ticket
        QUERY_CACHE_KEYS.initAsyncJobChatHistory(id),
      ];
      await Promise.all(
        invalidators.map((invalidator) =>
          queryClient.invalidateQueries(invalidator).catch((error) => {
            throw new Error(`Failed to invalidate query ${invalidator}`, {cause: error});
          }),
        ),
      ).catch((error) => {
        Sentry.captureException(error, {tags: {context: 'Progress report query invalidation'}});
      });
      feedbackRef.current = null;
      taskRef.current = null;
    },
    onError: (err) => {
      toast.error(t('dailies:progress_report.toast.error_updating'));
      Sentry.captureException(err);
      onClose?.();
    },
  });

  const {hasAnyAdminRole} = useCompanyWorkerRoles(projectId);

  const handleDailyFeedBack = (updates: DailyCardUpdates) => {
    const updatedFeedback = formik.values.feedback?.map((fb) => {
      if (fb.dateTag === updates.dateTag) {
        const dateTag = formatDate(updates.dateTag, 'MM/DD/YY');
        if (updates.dailyLabor !== fb.dailyLabor) {
          track(events.dailies.dailiesActivityPopupEnterActualLabor, {dateTag});
        }
        if (updates.dailyCompletionAmount !== fb.dailyCompletionAmount) {
          track(events.dailies.dailiesActivityPopupEnterProgress, {dateTag});
        }
        return updates;
      }
      return fb;
    });
    formik.setValues({...formik.values, feedback: updatedFeedback});
  };

  const handleChangeCompletionUnit = (completionUnit: string) => {
    const completionTarget =
      completionUnit === CompletionUnits.Percent ? ONE_HUNDRED_PERCENT.toString() : formik.values.task.completionTarget;
    const updatedFeedback = formik.values.feedback?.map((fb) => ({
      ...fb,
      completionUnit: completionUnit as CompletionUnits,
    }));
    formik.setValues({
      ...formik.values,
      feedback: updatedFeedback,
      task: {...formik.values.task, completionUnit: completionUnit as TaskCompletionUnits, completionTarget},
    });
  };

  const handleProjectLabor = (projectedLabor: string) => {
    const labor = parseAndValidateNumber(projectedLabor);
    formik.setValues({...formik.values, task: {...formik.values.task, projectedLabor: labor}});
  };

  const handleSubmit = async () => {
    track(events.dailies.dailiesActivityPopupSubmitButton);
    const validators = {
      completionTarget: formik.values.task.completionTarget,
      completionUnit: formik.values.task.completionUnit,
    };
    validationSchema
      .validate(validators, {abortEarly: false})
      .then(async (valid) => {
        if (valid) {
          setErrors(null);
        }
        if (formik.dirty) {
          onSubmit({...formik.values, initialFeedBack: feedbackRef.current});
          onClose?.();
        } else {
          onClose?.();
        }
      })
      .catch((err) => {
        if (err instanceof ValidationError) {
          const formattedErrors = err.inner.reduce((acc, currentError) => {
            if (currentError.path === 'completionAmount') {
              return {
                ...acc,
                [currentError.path]: t(normalizeI18Key(currentError.message), {
                  completionUnit: CompletionUnits.Percent,
                  completionTarget: ONE_HUNDRED_PERCENT,
                }),
              };
            }
            return {
              ...acc,
              [currentError.path]: t(normalizeI18Key(currentError.message)),
            };
          }, {});

          setErrors((prevErrors) => ({...prevErrors, ...formattedErrors}));
        } else {
          toast.error('Unknown error');
        }
      });
  };

  const formik = useFormik<FormValues>({
    initialValues: {
      comment: initialComment,
      feedback: data?.initialFeedback || [],
      image: initialImage,
      task: data?.initialTask || {},
    },
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  const cumulativeDailyCompletion = formik.values.feedback
    ? Math.max(...formik.values.feedback?.map(({dailyCompletionAmount}) => dailyCompletionAmount), 0)
    : 0;

  useImperativeHandle(ref, () => ({hasChanges: formik.dirty}), [formik.dirty]);

  const localEndDate = dayjs(formik.values.task?.endDate).startOf('day');
  const localStartDate = dayjs(formik.values.task?.startDate).startOf('day');
  const totalDays = `(${tasksStore.tasks.find((t) => t.id === taskId)?.duration ?? 1}d)`;

  const dailyCardsLoading = () =>
    Array.from({length: 4}, (_, i) => <Skeleton className={styles.skeleton} key={i.toString()} />);

  const dailyCards = () =>
    formik?.values?.feedback?.map((fb, index) => (
      <DailyCard
        completionTarget={formik.values.task.completionTarget}
        cumulativeDailyCompletion={cumulativeDailyCompletion}
        feedback={fb}
        gantt={gantt}
        handleUpdate={handleDailyFeedBack}
        index={index}
        key={fb.dateTag.toDateString()}
        onOpenComments={handleSubmit}
        readonlyMode={!hasAnyAdminRole}
        taskId={id}
      />
    ));

  const renderDailyCards = () => {
    if (isLoading) {
      return dailyCardsLoading();
    }
    return dailyCards();
  };

  function handleCompletionAmountChange(completionTarget: string) {
    if (errors?.completionAmount && completionTarget !== '') {
      setErrors((prevErrors) => ({...prevErrors, completionAmount: ''}));
    }
    validationSchema.validate({completionTarget}).catch((err) => {
      if (formik.values.task?.completionUnit === CompletionUnits.Percent) {
        setErrors((prev) => ({
          ...prev,
          completionAmount: t(err.message, {
            completionUnit: CompletionUnits.Percent,
            completionTarget: 100,
          }),
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          completionAmount: t(err.message),
        }));
      }
    });
    setErrors(null);
    const target =
      formik.values.task?.completionUnit === CompletionUnits.Percent
        ? ONE_HUNDRED_PERCENT.toString()
        : parseAndValidateNumber(completionTarget);
    formik.setValues({...formik.values, task: {...formik.values.task, completionTarget: target}});
  }

  function handleComment(values: CommentValues) {
    formik.setValues({...formik.values, ...values});
  }

  function unlockCompletionUnits() {
    trackWithAction(() => setShowUnitsUnlockPopup(true), events.dailies.dailiesActivityPopupUnitMeasurement);
  }

  return (
    <form ref={progressReportRef} className={styles.container} key={id} onSubmit={formik.handleSubmit}>
      <header className={cn(styles.row, styles.center)}>
        <SkeletonPreloader when={isLoading} height={24} width={200}>
          <h1 className={styles.title}>
            {formik.values.task?.name}
            {t('dailies:progress_report.header.dailies')}
          </h1>
          <HSpacer size="5" />
        </SkeletonPreloader>
      </header>

      <VSpacer size="7" />

      <section className={styles.subsection}>
        {/* Activity start and finish section */}
        <section className={styles.subtitle}>
          <SkeletonPreloader when={isLoading} height={16} width={100}>
            <span>{formatDate(localStartDate, 'M/D/YY')}</span>
            <Icon height={16} width={16} name="arrow-right" />
            <span>{formatDate(localEndDate, 'M/D/YY')}</span>
            <HSpacer />
            <span className={styles.light_text}>{totalDays}</span>
          </SkeletonPreloader>
        </section>

        {/* est labor */}
        <section>
          <SkeletonPreloader when={isLoading} height={16} width={200}>
            <InlineInput
              disabled={!hasAnyAdminRole}
              event={events.dailies.dailiesActivityPopupEstLaborEdit}
              label={t('dailies:progress_report.header.inputs.estimated_labor')}
              onChange={handleProjectLabor}
              type="number"
              value={formik.values.task?.projectedLabor}
            />
          </SkeletonPreloader>
        </section>

        {/* target amt section & completion units */}
        <section className={styles.row}>
          <SkeletonPreloader when={isLoading} height={16} width={100}>
            <InlineInput
              disabled={!hasAnyAdminRole || formik.values.task?.completionUnit === CompletionUnits.Percent}
              errorMessage={errors?.completionAmount}
              event={events.dailies.dailiesActivityPopupTargetAmount}
              label={t('dailies:progress_report.header.inputs.target_amount')}
              onChange={handleCompletionAmountChange}
              type="number"
              value={formik.values.task?.completionTarget}
            />
            <HSpacer size="3" />
            {lockCompletionUnits ? (
              <Button
                disabled={!hasAnyAdminRole}
                icon={<Icon name={IconsMap.lock} />}
                iconOnly
                onClick={unlockCompletionUnits}
              />
            ) : null}
            <InlineSelect
              isEditable={hasAnyAdminRole && !lockCompletionUnits}
              label={t('dailies:progress_report.header.inputs.units')}
              onChange={handleChangeCompletionUnit}
              options={taskCompletionUnitOptions}
              value={{
                label: completionUnitLabels[formik.values.task?.completionUnit],
                value: formik.values.task?.completionUnit as CompletionUnits,
              }}
            />
          </SkeletonPreloader>
        </section>
      </section>

      <VSpacer size="5" />

      <section className={styles.dailyCardsContainer}>{renderDailyCards()}</section>

      <VSpacer size="2" />

      <header>
        <h3>{t('dailies:progress_report.comments.comments_title')}</h3>
      </header>

      <ProgressReportCommentForm
        commentValues={{
          comment: formik.values.comment,
          image: formik.values.image,
        }}
        onChange={handleComment}
        taskId={id}
        onImageErrors={setChildImageErrors}
      />
      <VSpacer size="5" />

      <Button
        className={styles.button}
        disabled={
          !hasAnyAdminRole || Boolean(childImageErrors?.fileType?.length) || Boolean(childImageErrors?.fileSize?.length)
        }
        type="submit"
      >
        {t('dailies:progress_report.submit')}
      </Button>

      <ConfirmationPopup
        acceptButton={t('dailies:progress_report.confirm_edit_units.yes')}
        cancelButton={t('dailies:progress_report.confirm_edit_units.cancel')}
        description={t('dailies:progress_report.confirm_edit_units.body')}
        iconName="warning"
        onClose={() => setShowUnitsUnlockPopup(false)}
        visible={showUnitsUnlockPopup}
        onAccept={() => {
          setLockCompletionUnits(false);
          setShowUnitsUnlockPopup(false);
        }}
        onReject={() => setShowUnitsUnlockPopup(false)}
        title={t('dailies:progress_report.confirm_edit_units.title')}
      />
    </form>
  );
}

export default forwardRef(ProgressReport);

ProgressReport.displayName = 'ProgressReport';
