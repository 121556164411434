import cn from 'classnames';
import {FieldArray, Field, useFormikContext, FieldProps} from 'formik';
import {useCallback, useEffect, useMemo, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import TextareaAutosize from 'react-textarea-autosize';

import {getFieldTypesOptions} from 'modules/Tasks/components/ActionsBar/components/GanttColumnsDropdown/ProjectCustomColumnPopup/utils';
import Button from 'shared/components/Button';
import FormControl from 'shared/components/CoreForm/FormControl';
import CoreSelect from 'shared/components/CoreForm/Select/Select';
import Icon from 'shared/components/Icon';
import {DeleteCustomColumnProps, TaskImportField, TasksImportConfig} from 'shared/components/TasksImport/utils/types';
import Tooltip from 'shared/components/Tooltip';
import {IconsMap} from 'shared/constants/icons';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';
import {ProjectCustomFieldType} from 'shared/models/project';
import {useRootSelector} from 'store';
import {selectAllProjects} from 'store/projects';

import s from '../styles.module.scss';

import {validateCustomFieldName} from './schema';

type Option = {
  label: string;
  value: string;
};

type CustomColumnMappingProps = {
  fields: TaskImportField[];
  fieldsToSelect: TaskImportField[];
  fileHeaders: Option[];
  handleDeleteCustomColumn: (props: DeleteCustomColumnProps) => void;
  tasksIsLoading: boolean;
  taskIdOptions: Option[];
};

const CustomColumnMapping = ({
  fields,
  fieldsToSelect,
  fileHeaders,
  handleDeleteCustomColumn,
  taskIdOptions,
  tasksIsLoading,
}: CustomColumnMappingProps) => {
  const {t} = useTranslation('import');
  const {values, setFieldValue, isSubmitting} = useFormikContext<TasksImportConfig>();
  const customFieldRefs = useRef<(HTMLInputElement | null)[]>([]);
  const prevLengthRef = useRef(values.customFields.length);
  const projects = useRootSelector(selectAllProjects);
  const {mixpanel} = useAnalyticsService();
  const mixpanelEvents = mixpanel.events.tasks.import;

  const hasUniqueId = useMemo(() => fieldsToSelect.some((field) => field.key === 'uniqueId'), [fieldsToSelect]);

  const options = useMemo(
    () => (hasUniqueId ? taskIdOptions.filter((item) => item.value !== 'auto') : fileHeaders),
    [fileHeaders, hasUniqueId, taskIdOptions],
  );

  useEffect(() => {
    const currentLength = values.customFields.length;
    if (currentLength > prevLengthRef.current) {
      const lastIndex = currentLength - 1;
      customFieldRefs.current[lastIndex]?.focus();
      customFieldRefs.current[lastIndex]?.scrollIntoView({behavior: 'smooth'});
    }
    prevLengthRef.current = currentLength;
  }, [values.customFields.length]);

  const disabledOptions = useCallback(
    (option: {label: string; value: string}) =>
      [...Object.values(values.mapping), ...values.customFields.map((cf) => cf.mappedTo).filter(Boolean)].includes(
        option.value,
      ),
    [values.customFields, values.mapping],
  );

  return (
    <FieldArray
      name="customFields"
      render={(helpers) => (
        <>
          <div className={s['custom-column-heading']}>
            <h3>{t('mapping.form.custom_column.heading')}</h3>
          </div>
          {values.customFields?.length > 0
            ? values.customFields.map((col, idx) => (
                <div key={`customFields.${idx}`} className={cn('form-compare__control', s['custom-column-item'])}>
                  <FormControl name={`customFields.${idx}.fieldName`} className="form-compare__name">
                    <Field
                      name={`customFields.${idx}.fieldName`}
                      validate={(val: string) => validateCustomFieldName({col, idx, projects, t, val, values}, fields)}
                    >
                      {({field}: FieldProps) => (
                        <input
                          {...field}
                          ref={(el) => (customFieldRefs.current[idx] = el)}
                          value={field.value}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                          placeholder={t('mapping.form.custom_column.input_placeholder', 'Column Name')}
                          maxLength={20}
                          className={cn('form-compare__name', s['custom-column-input'])}
                        />
                      )}
                    </Field>
                  </FormControl>
                  <Tooltip text={t('mapping.form.custom_column.delete')}>
                    <Button
                      type="button"
                      buttonSize="xl"
                      iconOnly
                      disabled={tasksIsLoading || isSubmitting}
                      icon={
                        <Icon
                          className={cn(s['custom-column-delete'], {
                            [s['custom-column-delete--is-submitting']]: isSubmitting,
                          })}
                          size={24}
                          name={IconsMap.delete}
                        ></Icon>
                      }
                      onClick={async () => {
                        if (col.internalFieldName) {
                          handleDeleteCustomColumn({
                            customFields: values.customFields,
                            helpers,
                            idx,
                          });
                        } else {
                          helpers.remove(idx);
                        }
                        mixpanel.track(mixpanelEvents.deleteCustomColumn);
                      }}
                    >
                      {t('mapping.form.custom_column.delete')}
                    </Button>
                  </Tooltip>
                  <Icon colorFill className="form-compare__icon-compare" size={24} name="arrow_forward" />
                  <div className={cn('form-compare__item form-compare__item--compare', s['custom-column-label'])}>
                    <div className={s['custom-column-select-wrapper']}>
                      <FormControl
                        name={`customFields.${idx}.mappedTo`}
                        labelHidden
                        label={t('mapping.form.custom_column.mapped_to')}
                      >
                        <Field name={`customFields.${idx}.mappedTo`}>
                          {({field}: FieldProps) => (
                            <CoreSelect
                              {...field}
                              isLoading={isSubmitting}
                              isClearable
                              isDisabled={!col.fieldName || isSubmitting}
                              isOptionDisabled={disabledOptions}
                              isSearchable
                              menuPlacement="auto"
                              onChange={(newValue) => {
                                mixpanel.trackWithAction(
                                  () => setFieldValue(field.name, newValue),
                                  mixpanelEvents.customMappedTo,
                                  {[col.fieldName]: newValue},
                                );
                              }}
                              options={options}
                              placeholder={
                                isSubmitting
                                  ? t('mapping.form.select.placeholder.loading', 'Preparing data...')
                                  : t('mapping.form.select.placeholder.default', 'Select...')
                              }
                              value={field.value}
                            />
                          )}
                        </Field>
                      </FormControl>
                      <FormControl
                        name={`customFields.${idx}.fieldType`}
                        label={t('mapping.form.custom_column.column_type')}
                      >
                        <Field>
                          {({field}: FieldProps) => (
                            <CoreSelect
                              {...field}
                              isDisabled={!!col.internalFieldName}
                              menuPlacement="auto"
                              onChange={(val) => setFieldValue(field.name, val)}
                              options={getFieldTypesOptions(t)}
                            />
                          )}
                        </Field>
                      </FormControl>
                      {[ProjectCustomFieldType.select, ProjectCustomFieldType.multiselect].includes(
                        values.customFields[idx].fieldType,
                      ) ? (
                        <FormControl
                          name={`customFields.${idx}.fieldData`}
                          label={t('mapping.form.custom_column.select_multiselect.label')}
                        >
                          <Field>
                            {({field}: FieldProps) => (
                              <TextareaAutosize
                                {...field}
                                className="ctrl-textfield"
                                maxRows={3}
                                minRows={2}
                                placeholder={t('mapping.form.custom_column.select_multiselect.placeholder')}
                              />
                            )}
                          </Field>
                        </FormControl>
                      ) : null}
                    </div>
                  </div>
                </div>
              ))
            : null}
        </>
      )}
    />
  );
};

export default CustomColumnMapping;
