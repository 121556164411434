import {TaskStates} from 'shared/models/task/const';
import {TaskFilterQuery} from 'shared/models/task/filter';

export const QUERY_CACHE_KEYS = {
  assignees: 'assignees',
  checkStatusAsyncJobChatHistory: (asyncJobId: string) => ['checkStatusAsyncJobChatHistory', asyncJobId],
  cloneProjectStatus: (projectId: string, asyncUploadId: string) => ['cloneProjectStatus', projectId, asyncUploadId],
  commentsFeedback: 'commentsFeedback',
  companySubcontractors: (companyId: string) => ['companyorgs', {id: companyId}],
  crews: (projectId: string) => ['crews', projectId],
  dailiesGeneralComments: 'dailiesGeneralComments',
  dailiesIssues: 'dailiesIssues',
  dailiesReport: (projectId: string, queryParams: TaskFilterQuery) => [
    'dailiesReport',
    projectId,
    JSON.stringify(queryParams),
  ],
  dailiesTaskFeedback: (taskId: string) => ['dailiesTaskFeedback', taskId],
  dailyFeedback: 'dailyFeedback',
  feedback: (taskId: string) => ['feedback', taskId],
  getGroupById: (groupId: string) => ['get-group-by-id', groupId],
  initAsyncJobChatHistory: (taskId: string) => ['initAsyncJobChatHistory', taskId],
  issuesPanel: 'issues_panel',
  joinedRooms: (accessToken: string, userId) => ['joined_rooms', accessToken, userId],
  linkedActivitiesWithIssue: (issueId: string) => ['linkedActivitiesWithIssue', issueId],
  matrixToken: 'matrix_token',
  project: 'project',
  projectDependencies: (projectId?: string, ganttName?: string) => ['tasksDependencies', projectId, ganttName],
  projectMostRecentCollabRequest: (projectId: string) => ['projectMostRecentCollabRequest', projectId],
  projectRowMap: (projectId?: string) => ['rownumberMap', projectId],
  projectSubcontractors: (projectId: string) => ['projectorgs', {id: projectId}],
  projectSubcontractorsWithPaginate: (projectId: string) => ['paginate_projectorgs', {id: projectId}],
  projectWorkers: (projectId?: string) => ['projectWorkers', projectId],
  projectWorkersWithPaginate: (projectId: string) => ['paginate_projectWorkers', projectId],
  projects: 'projects',
  rootLevelWbs: (projectId: string, taskState = TaskStates.active) => ['rootLvlWbs', projectId, taskState],
  task: (id: string) => ['task', id],
  taskTypes: (projectId: string) => ['taskTypes', projectId],
  unreadCount: (accessToken: string, userId: string, roomIds: string[]) => [
    'unread_count',
    accessToken,
    userId,
    ...roomIds,
  ],
  worker: 'worker',
  workers: 'workers',
  zones: (projectId: string) => ['zones', projectId],
} as const;
