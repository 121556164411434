import * as Sentry from '@sentry/browser';
import cn from 'classnames';
import dayjs from 'dayjs';
import {Formik, Form, FieldProps} from 'formik';
import {forwardRef, Fragment, RefObject, useEffect, useImperativeHandle, useMemo, useRef} from 'react';
import {TFunction, useTranslation} from 'react-i18next';
import {useMutation, useQueryClient} from 'react-query';
import {object, string, array, boolean, mixed, ValidationError} from 'yup';

import {container} from 'IocContainer';
import DatePicker from 'shared/components/CoreForm/DatePicker';
import CtrlButton from 'shared/components/CoreNewUI/CtrlButton';
import FormControl from 'shared/components/CoreNewUI/FormControl/FormControl';
import Icon from 'shared/components/Icon';
import Loader from 'shared/components/Loader';
import {Pill} from 'shared/components/Pill/Pill';
import {useInitializeMatrixSession} from 'shared/components/ProgressReport/hooks/useInitializeMatrixSession';
import {CreateComment, handleSubmitComment} from 'shared/components/ProgressReport/utils/apiHelpers';
import {SUPPORTED_FORMATS, FILE_SIZE_LIMIT} from 'shared/components/ProgressReport/utils/validationSchema';
import Tooltip from 'shared/components/Tooltip';
import {IconsMap} from 'shared/constants/icons';
import {QUERY_CACHE_KEYS} from 'shared/constants/queryCache';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';
import {MessageTypeMap} from 'shared/models/feedback';
import {IOC_TYPES} from 'shared/models/ioc';
import {IssueModel} from 'shared/models/task/issue';
import {TaskModelRawDTO} from 'shared/models/task/task';
import {useInjectStore} from 'shared/providers/injection';
import {GanttStore} from 'shared/stores/GanttStore';
import {TasksStoreType} from 'shared/stores/TasksStore';

import s from './styles.module.scss';

type FormDataByType = {
  comment: {
    comment: string;
    dateTag: Date;
    feedbackType: typeof MessageTypeMap.message;
  };
  image: {
    images: File[];
    feedbackType: typeof MessageTypeMap.image;
    dateTag: Date;
  };
  flagAsPotentialIssue: boolean;
};

type CommentResponse = {
  comment_count?: number;
};

type SubmitHandlers = {
  onSuccess: (response: CommentResponse) => void;
  onError: (error: unknown, variables: CreateComment, context: unknown) => void;
};

const commentFormValidationSchema = (t: TFunction) => {
  return object({
    date: string(),
    comment: string().nullable(),
    potentialIssue: boolean(),
    files: array()
      .of(
        mixed().test(
          'fileSize',
          'File size is too large', // Default error message
          (file, context) => {
            if (
              file.size > FILE_SIZE_LIMIT ||
              !SUPPORTED_FORMATS.includes(
                // rare case since we only "accept" filetypes listed in SUPPORTED_FORMATS on the <input />
                file.type,
              )
            ) {
              // Throw a validation error with a custom message
              return context.createError({
                message: file.name,
              });
            }
            return true; // The file is valid
          },
        ),
      )
      .nullable(),
  }).test('comment-or-files-required', 'Comment or files are required', (values) => {
    const commentIsNotEmpty = values.comment && values.comment.trim() !== '';
    const filesAreProvided = values.files && values.files.length > 0;
    if (!commentIsNotEmpty && !filesAreProvided) {
      // If neither comment nor files are provided, throw a validation error
      return new ValidationError(
        t('activity_panel.comment_form.validation.comment_or_image_required'),
        undefined,
        'comment-or-files-required',
      );
    }
    return true; // The form is valid
  });
};

const initialValues = {
  date: dayjs().format('YYYY-MM-DD'),
  comment: '',
  potentialIssue: false,
  files: [] as File[],
};

type CommentFormValues = typeof initialValues;

interface CommentFormProps {
  isFormVisible: boolean;
  style: Record<string, string | number>;
  setIsCommentFormVisible: (value: boolean) => void;
  taskId: string;
  issue: IssueModel;
}

export interface CommentFormRef {
  focusInput: () => void;
  containerRef: RefObject<HTMLDivElement>;
}

export const CommentForm = forwardRef<CommentFormRef, CommentFormProps>(
  ({isFormVisible, style, setIsCommentFormVisible, taskId, issue}, ref) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const {t} = useTranslation('comments');
    const fileInput = useRef<HTMLInputElement>(null);
    const {data} = useInitializeMatrixSession();
    const queryClient = useQueryClient();
    const tasksStore = useInjectStore<TasksStoreType>(IOC_TYPES.TasksStore);
    const {
      mixpanel: {track, events},
    } = useAnalyticsService();
    const commentEvents = events.tasks.sidePanel.comments;
    const gantt = container.get<GanttStore>(IOC_TYPES.GanttStore);

    // Create a helper to determine which entity we're working with
    const entity = useMemo(() => {
      if (issue) {
        return {
          type: 'issue',
          data: issue,
          dateList: issue.dateList,
          commentCount: issue.commentCount ?? 0,
        };
      }

      const task = tasksStore.getTaskById(taskId);

      return {
        type: 'task',
        data: task,
        dateList: task?.date_list,
        commentCount: task?.comment_count,
      };
    }, [issue, taskId, tasksStore]);

    const {mutate: onCommentFromSubmit, isLoading} = useMutation(async (updates: CreateComment) =>
      handleSubmitComment({
        updates,
        accessToken: data?.accessToken,
        taskId,
        projectId: issue?.projectId,
        issueId: issue?.id,
      }),
    );

    useImperativeHandle(ref, () => {
      return {
        containerRef,
        focusInput: () => inputRef.current.focus(),
      };
    });

    useEffect(() => {
      if (isFormVisible && inputRef.current) {
        inputRef.current.focus();
      }
    }, [isFormVisible]);

    const filterDate = (selectedDate: Date) => {
      const startOfSelectedDate = dayjs(selectedDate).startOf('day');
      const isToday = startOfSelectedDate.isSame(dayjs().startOf('day'), 'day');
      const isInDateList = entity.dateList?.some((date) =>
        startOfSelectedDate.isSame(dayjs(date).startOf('day'), 'day'),
      );
      return isToday || isInDateList;
    };

    function getDayClassName(day: Date) {
      return filterDate(day) ? 'filteredDate' : null;
    }

    const handleCommentSubmit = (formDataByType: FormDataByType, {onSuccess, onError}: SubmitHandlers) => {
      onCommentFromSubmit(formDataByType, {
        onSuccess: (response: CommentResponse) => {
          if (entity.type === 'task') {
            const updatedTask = {
              ...entity.data,
              comment_count: Number(entity.commentCount ?? 0) + response?.comment_count ?? 0,
            } as TaskModelRawDTO;
            tasksStore.updateTasks([updatedTask]);
          } else {
            const currentIssue = gantt.ganttInstance.getTask(entity.data.id);
            gantt.ganttInstance.updateTask(entity.data.id, {
              ...currentIssue,
              comment_count: Number(currentIssue.comment_count ?? 0) + response?.comment_count ?? 0,
            });
          }
          const invalidateId = entity.type === 'task' ? taskId : issue?.id;
          queryClient.invalidateQueries(QUERY_CACHE_KEYS.feedback(invalidateId));
          onSuccess(response);
        },
        onError: (error: unknown, variables: CreateComment, context: unknown) => {
          Sentry.captureException(error, {
            tags: {
              feature: `activity-panel-comment-form-${entity.type}`,
              entityType: entity.type,
            },
          });
          onError(error, variables, context);
        },
      });
    };

    return (
      <>
        <div
          className={cn(s['comment-form'], {
            [s.visible]: isFormVisible,
            [s.hidden]: !isFormVisible,
          })}
          style={style}
          ref={containerRef}
        >
          {isLoading ? <Loader /> : null}
          <Formik<CommentFormValues>
            initialValues={initialValues}
            validationSchema={commentFormValidationSchema(t)}
            onSubmit={(values, {resetForm, setSubmitting, setErrors, setTouched}) => {
              const formDataByType = {
                comment: {
                  comment: values.comment,
                  dateTag: dayjs(values.date).toDate(),
                  feedbackType: MessageTypeMap.message,
                },
                image: {
                  images: Array.from(values.files),
                  feedbackType: MessageTypeMap.image,
                  dateTag: dayjs(values.date).toDate(),
                },
                flagAsPotentialIssue: values.potentialIssue,
              };

              handleCommentSubmit(formDataByType, {
                onSuccess: () => {
                  setTouched({
                    comment: false,
                    date: false,
                    files: [],
                    potentialIssue: false,
                  });
                  resetForm();
                  setErrors({});
                  setIsCommentFormVisible(false);
                  setSubmitting(false);
                },
                onError: () => {
                  setSubmitting(false);
                },
              });
            }}
          >
            {({isSubmitting, setValues, setFieldValue, setFieldError, values, errors}) => {
              const oversizedFileNames = values.files?.reduce((acc, file, index) => {
                if (errors.files && errors.files[index]) {
                  acc.push(file.name);
                }
                return acc;
              }, []);
              return (
                <Form>
                  <div className={s['comment-form--input-container']}>
                    <div className={s['comment-form--date-picker-checkbox-container']}>
                      <FormControl.Formik
                        name="potentialIssue"
                        labelIcon={
                          <Tooltip text={t('activity_panel.comment_form.potential_issue.flag_issue_tooltip')}>
                            <div className={s['label-icon-container']}>
                              <Icon name={IconsMap.help} />
                            </div>
                          </Tooltip>
                        }
                        label={t('activity_panel.comment_form.potential_issue.label')}
                      >
                        {({field: {onChange, name, value, ...fieldProps}}: FieldProps) => (
                          <label className="ctrl-toggle">
                            <input
                              {...fieldProps}
                              name={name}
                              checked={value}
                              className="ctrl-toggle__field"
                              onInputCapture={() => track(commentEvents.flagIssue, {checked: value})}
                              onChange={onChange}
                              type="checkbox"
                            />
                            <span className="ctrl-toggle__label ctrl-toggle__label--default">Unflag</span>
                            <span className={cn('ctrl-toggle__visual', {[s.checked]: value})} />
                            <span className="ctrl-toggle__label ctrl-toggle__label--second">Flag</span>
                          </label>
                        )}
                      </FormControl.Formik>

                      <FormControl.Formik name="date" label={t('activity_panel.comment_form.refer_to_issue.label')}>
                        {({field: {value, onChange, ...fieldProps}}) => (
                          <DatePicker
                            {...fieldProps}
                            calendarClassName={s['react-datepicker-overrides']}
                            dayClassName={getDayClassName}
                            minDate={dayjs('1925-01-01').toDate()}
                            maxDate={dayjs().toDate()}
                            selected={value ? dayjs(value).toDate() : dayjs().toDate()}
                            onChange={(date) => {
                              onChange({target: {value: date, name: 'date'}});
                            }}
                            filterDate={entity.type === 'task' ? filterDate : undefined}
                            onCalendarOpen={() => track(commentEvents.clickCalendar)}
                          />
                        )}
                      </FormControl.Formik>
                    </div>
                  </div>

                  <div className={s['comment-form--input-container']}></div>

                  <div className={s['comment-form--input-container']}>
                    <FormControl.Formik
                      name="files"
                      label={t('activity_panel.comment_form.images.label')}
                      errorClassName={s['error-message-hidden']}
                    >
                      {({field: {name, value}}: FieldProps) => (
                        <>
                          <input
                            ref={fileInput}
                            className={s['files']}
                            name={name}
                            type="file"
                            accept="image/jpeg, image/jpg, image/png"
                            multiple
                            onClick={() => track(commentEvents.addPhoto)}
                            onChange={(event) => {
                              const newFiles = Array.from(event.currentTarget.files as FileList);
                              setFieldValue(name, [...value, ...newFiles]);
                              event.currentTarget.value = '';
                            }}
                          />
                          <CtrlButton
                            type="button"
                            className={s['file-upload-button']}
                            color="second"
                            size="s"
                            shadow
                            icon={IconsMap.image}
                            onClick={() => {
                              fileInput.current?.click();
                            }}
                            disabled={isSubmitting}
                          >
                            {t('activity_panel.comment_form.images.cta')}
                          </CtrlButton>
                        </>
                      )}
                    </FormControl.Formik>

                    {oversizedFileNames.length > 0 ? (
                      <span className={s['error-message']}>
                        {t('activity_panel.comment_form.validation.file_size_error', {
                          files: oversizedFileNames.join(', '),
                        })}
                      </span>
                    ) : null}

                    <div className={s['file-list-container']}>
                      {values.files?.map((file, index) => {
                        const hasError = oversizedFileNames.includes(file.name);
                        const pillColor = hasError ? 'red' : null;

                        return (
                          <Fragment key={file.name + index}>
                            <Pill
                              className={s.pill}
                              color={pillColor}
                              onClick={() => {
                                setValues({
                                  ...values,
                                  files: values.files.filter((_, i) => i !== index),
                                });
                                setFieldError(`files[${index}]`, undefined);
                              }}
                              icon={<Icon name={IconsMap.delete} />}
                            >
                              {file.name}
                            </Pill>
                          </Fragment>
                        );
                      })}
                    </div>
                  </div>

                  <div className={cn(s['comment-form--input-container'], s['comment-input-container'])}>
                    <FormControl.Formik
                      name="comment"
                      label={t('activity_panel.comment_form.comment.label')}
                      errorClassName={s['error-message']}
                    >
                      {({
                        field: {name, value, onBlur, onChange, ...fieldProps},
                        form: {setFieldTouched},
                      }: FieldProps) => (
                        <input
                          {...fieldProps}
                          onChange={onChange}
                          onBlur={(e) => {
                            onBlur(e);
                            setFieldTouched(name, true, true);
                          }}
                          name={name}
                          value={value}
                          ref={inputRef}
                          type="text"
                        />
                      )}
                    </FormControl.Formik>

                    <Tooltip text={t('activity_panel.comment_form.form_submit.cta')}>
                      <CtrlButton
                        type="submit"
                        aira-label="Submit"
                        iconOnly
                        className={s['comment-form--submit-button']}
                        color="second"
                        size="xs"
                        shadow
                        icon={IconsMap.submit}
                        disabled={isSubmitting}
                        onClick={() => track(commentEvents.submitComment)}
                      />
                    </Tooltip>
                  </div>
                  {errors['comment-or-files-required'] ? (
                    <span className={s['error-message']}>{errors['comment-or-files-required']}</span>
                  ) : null}
                </Form>
              );
            }}
          </Formik>
        </div>
      </>
    );
  },
);

CommentForm.displayName = 'CommentForm';
