import {useMemo} from 'react';
import {useQuery} from 'react-query';

import FeedbackService from 'api/feedback';
import {QUERY_CACHE_KEYS} from 'shared/constants/queryCache';

type Props = {
  projectId?: string;
  taskId: string;
  issueId?: string;
};

export function useFetchFeedback(params: Props) {
  const queryKey = useMemo(() => {
    if (params.issueId && params.projectId) {
      return QUERY_CACHE_KEYS.feedback(params.issueId);
    }
    return QUERY_CACHE_KEYS.feedback(params.taskId);
  }, [params.issueId, params.projectId, params.taskId]);

  const feebackFn = () => {
    if (params.issueId && params.projectId) {
      return FeedbackService.getIssueFeedback(params.issueId, params.projectId);
    }
    return FeedbackService.getFeedback(params.taskId);
  };

  return useQuery(queryKey, feebackFn, {
    enabled: Boolean(params.taskId || params.issueId),
    staleTime: 0,
  });
}
