import {TFunction} from 'react-i18next';
import {array, boolean, mixed, object, SchemaOf, string} from 'yup';

import {getGanttColumnsLabels} from 'modules/Tasks/components/Gantt/utils/constants';
import {
  TaskImportClientMapping,
  TasksImportConfig,
  TaskImportClientMappingKeys,
  StaticTaskImportClientMappingKeys,
  TaskImportProjectCustomFieldDef,
  TaskImportField,
} from 'shared/components/TasksImport/utils/types';
import {toTitleCase} from 'shared/helpers/common';
import {ProjectCustomFieldType, ProjectModel} from 'shared/models/project';

export const schema = (t: TFunction): SchemaOf<TasksImportConfig> =>
  object({
    importWithHierarchy: boolean().nullable(),
    dateFormat: string().nullable().required(t('import:required')),
    defaultProject: string().nullable().required(t('import:required')),
    mapping: object(
      TaskImportClientMappingKeys.reduce((acc, key) => {
        acc[key] =
          key === 'completionUnit'
            ? mixed()
                .nullable()
                .when('completionTarget', {
                  is: (completionTarget: string) => !!completionTarget,
                  then: string().required(t('import:required')),
                  otherwise: string().nullable(),
                })
            : ['uniqueId', 'name', 'schedStartDate', 'schedEndDate'].includes(key)
            ? string().nullable().required(t('import:required'))
            : string().nullable();
        return acc;
      }, {} as Record<StaticTaskImportClientMappingKeys, any>),
    ).required(),
    customFields: array().of(
      object({
        fieldData: string().nullable(),
        fieldName: string().required(t('import:required')),
        fieldType: mixed().oneOf(Object.values(ProjectCustomFieldType)).required(t('import:required')),
        internalFieldName: string().nullable(),
        mappedTo: string().nullable(),
      }),
    ),
  });

export function getDefaultValues(dateFormat: string): TasksImportConfig {
  return {
    customFields: [],
    importWithHierarchy: false,
    dateFormat: dateFormat,
    defaultProject: null,
    mapping: {
      actualEnd: null,
      actualStart: null,
      archived: null,
      assignedNames: null,
      completionTarget: null,
      completionUnit: null,
      cost: null,
      costCode: null,
      csiCode: null,
      customCode: null,
      description: null,
      estLaborHours: null,
      location: null,
      name: null,
      outlineCode: null,
      phaseCode: null,
      predecessors: null,
      projectedLabor: null,
      schedEndDate: null,
      schedStartDate: null,
      shouldImport: null,
      subcontractor: null,
      type: null,
      uniqueId: null,
    },
  };
}

export function getMapping(
  formikValues,
  importSettings,
  fileHeaders: {label: string; value: string}[],
): TaskImportClientMapping {
  const availableHeaders = fileHeaders.map((header) => header.value);
  return Object.entries(formikValues).reduce((acc, [key]) => {
    const savedValue = key in importSettings ? importSettings[key] : null;
    const validValue = savedValue && availableHeaders.includes(savedValue) ? savedValue : null;
    acc[key] = validValue;
    return acc;
  }, {} as TaskImportClientMapping);
}

type CustomFieldName = {
  col: TaskImportProjectCustomFieldDef;
  idx: number;
  projects: ProjectModel[];
  t: TFunction;
  val: string;
  values: TasksImportConfig;
};

export const validateCustomFieldName = (params: CustomFieldName, importFields: TaskImportField[]) => {
  if (!params.val) {
    return;
  }

  const {col, idx, projects, t, val, values} = params;

  const normalizedValue = toTitleCase(val?.trim().replace(/\s+/g, ' '));
  const project = projects.find((project) => project.id === values.defaultProject);
  const isDuplicateInProject =
    project &&
    project?.customFieldDef?.some(
      (field) => field.fieldName === normalizedValue && col.internalFieldName !== field.internalFieldName,
    );
  const isDuplicateInCustomFields = values.customFields.some(
    ({fieldName}, fieldIdx) =>
      fieldIdx !== idx && toTitleCase(fieldName?.trim().replace(/\s+/g, ' ')) === normalizedValue,
  );
  const isDuplicateImportField = importFields.some(({label}) => label === normalizedValue);

  const ganttColumnLabels = Object.values(getGanttColumnsLabels(t));
  const isDuplicateColumnLabel = ganttColumnLabels.some(
    (label) => toTitleCase(label?.trim().replace(/\s+/g, ' ')) === normalizedValue,
  );

  if (isDuplicateInCustomFields || isDuplicateInProject || isDuplicateImportField || isDuplicateColumnLabel) {
    return t('import:errors.messages.custom_column_name');
  }
  return undefined;
};
