import cn from 'classnames';
import {GanttStatic} from 'dhtmlx-gantt';
import equal from 'fast-deep-equal';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useQuery, useQueryClient} from 'react-query';
import {useHistory} from 'react-router';
import {toast} from 'react-toastify';

import TasksApi from 'api/tasks';
import PanelSection from 'modules/Tasks/components/SidebarPanel/components/PanelSection/PanelSection';
import {useTasksObserver} from 'services/TasksObserver/TasksObserverProvider';
import CtrlButton from 'shared/components/CoreNewUI/CtrlButton';
import {QUERY_CACHE_KEYS} from 'shared/constants/queryCache';
import {safeParseDate, toShortIso} from 'shared/helpers/dates';
import {useUnmount} from 'shared/hooks/core/useUnmount';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';
import {useCompanyWorkerRoles} from 'shared/hooks/useCompanyWorkerRoles';
import {useProjectSelector} from 'shared/hooks/useProjectSelector';
import {useDistinctProjectWorkers} from 'shared/hooks/useProjectWorkers';
import {GroupMemberRole} from 'shared/models/task/const';
import {IssueMinModel} from 'shared/models/task/issue';
import {IssueModelDTO, TaskDetailsModelDTO} from 'shared/models/task/task';
import {TaskStatusType} from 'shared/models/task/taskStatus';

import Issue from './Issue';
import s from './IssuesList.module.scss';

const NEW_ISSUE_ID = 'new';

type IssuesListProps = {
  className?: string;
  disabled?: boolean;
  gantt: GanttStatic;
  onOpenIssue: (id: string) => void;
  parent: TaskDetailsModelDTO;
  projectId: string;
};

const IssuesList = ({className, disabled, gantt, onOpenIssue, parent, projectId}: IssuesListProps) => {
  const taskId = parent?.id;
  const project = useProjectSelector(projectId);
  const observer = useTasksObserver();
  const history = useHistory<{newIssueFocus: boolean; currentIssueFocus: boolean; taskId?: string}>();
  const location = history.location;
  const currentIssueFocus = !!location?.state?.currentIssueFocus && !!parent;
  const [issueIdToFocus, setIssueIdToFocus] = useState(null);
  const {projectWorkers, isLoading: isLoadingWorkers} = useDistinctProjectWorkers(projectId);
  const [issues, setIssues] = useState(null);
  const {t} = useTranslation(['task']);
  const {mixpanel} = useAnalyticsService({extraMeta: {'Project Name': project?.name, projectId}});
  const mixpanelEvents = mixpanel.events.tasks.sidePanel;
  const {hasAnyAdminRole} = useCompanyWorkerRoles(projectId);
  const isScoped = !hasAnyAdminRole;
  const queryClient = useQueryClient();

  const {
    data,
    isLoading: isIssuesLoading,
    refetch: refetchIssues,
    remove,
  } = useQuery(
    ['panelActivityIssues', taskId],
    async () => {
      if (!parent.issueTaskIds?.length) {
        return [];
      }
      return await TasksApi.getTaskIssuesById(projectId, parent.issueTaskIds);
    },
    {
      enabled: !!projectId,
      refetchOnWindowFocus: false,
    },
  );
  const isNewIssueFocus = !isIssuesLoading && !!location?.state?.newIssueFocus && !!parent;

  useEffect(() => {
    refetchIssues();
    queryClient.invalidateQueries([QUERY_CACHE_KEYS.dailiesIssues, projectId]);
  }, [JSON.stringify(parent?.issueTaskIds)]);

  const addIssue = useCallback(() => {
    const issueId = NEW_ISSUE_ID;
    setIssues((prevIssues) => {
      const newIssues = (prevIssues || []).concat({
        id: issueId,
        name: 'New Issue',
        projectId: projectId,
        startDate: toShortIso(new Date()),
        endDate: null,
      });

      setIssueIdToFocus(issueId);

      return newIssues;
    });

    mixpanel.track(mixpanelEvents.createIssue);
  }, [issues, mixpanel, mixpanelEvents.createIssue]);

  useEffect(() => {
    if (data) {
      setIssues(data);
    }
  }, [data]);

  useEffect(() => {
    // If issue fetch is complete and has newissuefocus query param, add a new one
    if (issues instanceof Array && isNewIssueFocus) {
      const hasNewIssue = issues.some((issue) => issue.id === NEW_ISSUE_ID);
      if (!hasNewIssue) {
        addIssue();
      }
      const nextState = {...location.state};
      delete nextState.newIssueFocus;
      if (nextState.taskId) {
        delete nextState.taskId;
      }
      history.replace({
        pathname: history.location.pathname,
        search: history.location.search,
        state: nextState,
      });
    }
  }, [addIssue, history, location.state, isNewIssueFocus, issues]);

  useEffect(() => {
    if (issues instanceof Array && currentIssueFocus) {
      const nextState = {...location.state};
      const idToFocus = nextState.currentIssueFocus;
      delete nextState.currentIssueFocus;
      history.replace({
        pathname: history.location.pathname,
        search: history.location.search,
        state: nextState,
      });
      if (issues.length > 0) {
        setIssueIdToFocus(typeof idToFocus === 'number' ? idToFocus : issues[0].id);
      }
    }
  }, [history, issues, location.state, currentIssueFocus]);

  const getUpdatedFields = (
    original: Partial<IssueModelDTO>,
    updated: Partial<IssueModelDTO>,
  ): Partial<IssueModelDTO> => {
    const fields: Partial<IssueModelDTO> = {
      id: original.id,
      projectId,
    };

    for (const key of Object.keys(original)) {
      if (key !== 'responsible' && original[key] != updated[key]) {
        if (key === 'startDate' || key === 'endDate') {
          // tasks can't be updated without both start & end
          fields.startDate = updated.startDate;
          fields.endDate = updated.endDate;
        }
        fields[key] = updated[key];
      }
    }

    return fields;
  };

  // Transform model into object suitable for calling TasksApi.updateIssue
  const getIssueColumns = (issue: IssueMinModel): Partial<IssueModelDTO> => {
    let fields = {
      id: issue.id,
      projectId: projectId,
      status: issue.status,
    } as Partial<IssueModelDTO>;
    if (hasAnyAdminRole || issue.id === NEW_ISSUE_ID) {
      fields = {
        ...fields,
        name: issue.name?.trim(),
        description: issue.description?.trim(),
        impact: issue.impact,
        responsibleOrgId: issue.responsibleOrgId,
        culpableOrgId: issue.culpableOrgId,
        issueType: issue.issueType,
        startDate: toShortIso(issue.startDate),
        endDate: issue.endDate ? toShortIso(issue.endDate) : undefined,
      };
    }
    return fields;
  };

  const loading = isIssuesLoading || isLoadingWorkers;

  useUnmount(() => {
    remove();
  });

  const deleteIssue = async (issueId: string) => {
    mixpanel.track(mixpanelEvents.deleteIssue);
    if (issueId === NEW_ISSUE_ID) {
      setIssues(issues.filter((issue) => issue.id !== issueId));
    } else {
      await TasksApi.deleteIssue(issueId, projectId);
      await refetchIssues();
      const parentTask = gantt.getTask(parent.id);
      const issuesPairsIndex = parentTask.status_issue_task_ids_pairs.findIndex((pair) =>
        pair.issue_task_ids.includes(issueId),
      );
      if (issuesPairsIndex !== -1) {
        parentTask.status_issue_task_ids_pairs[issuesPairsIndex].issue_task_ids =
          parentTask.status_issue_task_ids_pairs[issuesPairsIndex].issue_task_ids.filter((id) => id !== issueId);
      }
      parentTask.issue_task_ids = parentTask.issue_task_ids.filter((id) => id !== issueId);

      gantt.updateTask(parent.id, {...parentTask});
      gantt.refreshTask(parent.id);

      observer.load({
        ids: [parent.id],
        projectId: project.id,
      });
    }
  };

  const preparedIssues = useMemo(() => {
    return (
      issues?.map((issue) => {
        const {
          culpableOrgId,
          description,
          endDate,
          id,
          name,
          projectId,
          responsible,
          responsibleOrgId,
          startDate,
          status,
          taskIds,
        } = issue;
        return {
          culpableOrgId,
          description,
          endDate: safeParseDate(endDate),
          id,
          impact: issue.impact,
          issueType: issue.issueType,
          name,
          projectId: projectId,
          responsible: responsible?.length ? responsible[0].memberId : null,
          responsibleOrgId,
          startDate: safeParseDate(startDate),
          status,
          taskIds,
        } as IssueMinModel;
      }) || []
    );
  }, [issues]);

  const onUpdate = async (data: IssueMinModel) => {
    let refetch = false;
    let issueId = data.id;
    const currentIssue: IssueMinModel = preparedIssues.find((issue) => issue.id === data.id);

    const updateFields = getIssueColumns(data);
    if (issueId === NEW_ISSUE_ID) {
      // New issue
      mixpanel.track(mixpanelEvents.issue);
      const assignees = data.responsible
        ? [
            {
              memberId: data.responsible,
              memberRole: GroupMemberRole.responsible,
            },
          ]
        : null;
      delete updateFields.id;
      updateFields.status = assignees?.length > 0 ? TaskStatusType.inProgress : TaskStatusType.tba;
      updateFields.taskIds = [parent.id];
      const payload = isScoped
        ? {
            ...updateFields,
            assignees,
          }
        : updateFields;

      const newIssue = await TasksApi.createIssue(payload, isScoped);
      issueId = data.id = newIssue.id;
      refetch = true;
      toast.success(t('task:activity_issues.created', 'Issue created.'));

      observer.load({
        ids: [parent.id],
        projectId: project.id,
      });
    } else {
      const originalFields = getIssueColumns(currentIssue);
      if (!equal(updateFields, originalFields)) {
        const changedFieldsOnly = getUpdatedFields(originalFields, updateFields);
        if (Object.keys(changedFieldsOnly).length > 2) {
          await TasksApi.updateIssue(changedFieldsOnly, isScoped);
          refetch = true;
          toast.success(t('task:activity_issues.created', 'Issue updated.'));

          observer.load({
            ids: currentIssue.taskIds,
            projectId: project.id,
          });
        }
      }
    }

    if (data.responsible && currentIssue?.responsible != data.responsible && !isScoped) {
      await TasksApi.addIssueAssignee(projectId, issueId, {
        memberId: data.responsible,
        memberRole: GroupMemberRole.responsible,
      });
      refetch = true;
    }

    gantt.updateIssuesDictionary();

    if (refetch) {
      await refetchIssues();
    }
  };

  return (
    <PanelSection
      className={s.panelActivityInfo__item}
      title={t('task:activity_issues.title', 'Issues')}
      width="narrow"
      headerAction={
        !disabled ? (
          <CtrlButton view="link" onClick={addIssue} disabled={disabled}>
            {t('task:activity_issues.create', 'Create Issue')}
          </CtrlButton>
        ) : null
      }
      description={!preparedIssues?.length && t('task:activity_issues.empty_desc', 'You can create an issue.')}
    >
      <div className={cn(s.issuesList, className)}>
        {preparedIssues?.map((issue) => (
          <Issue
            className={s.issuesList__item}
            data={issue}
            disabled={disabled}
            focus={issueIdToFocus === issue.id}
            key={issue.id}
            loading={loading}
            onChange={onUpdate}
            onDelete={() => deleteIssue(issue.id)}
            onOpenIssue={() => onOpenIssue(issue.id)}
            parent={parent}
            projectId={projectId}
            projectName={project?.name}
            workers={projectWorkers}
          />
        ))}
      </div>
    </PanelSection>
  );
};

export default IssuesList;
